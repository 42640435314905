@font-face {
  font-family: "Merienda";
  src: url("./assets/fonts/Jost-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Merienda";
  src: url("./assets/fonts/Merienda-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Merienda&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Merienda&family=Roboto&display=swap");

/* body{
 background: url(./assets/images/bodybackground.jpg) no-repeat fixed top center;
background-size: cover;
} */
.lg-font {
  font-size: 50px !important;
}
.chutti {
  mix-blend-mode: darken;
}
.regular {
  font-family: "Merienda";
}
.bold {
  font-family: "Merienda";
}
/* default start */
.overflow-hide {
  overflow: hidden;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.over {
  overflow: hidden;
}
.padding {
  padding: 70px;
}
.foot-pad {
  padding: 30px 0;
}
.navv-bg {
  background-color: white !important;
  --background: url(./assets/images/crackergif.gif);
  background-position: center center;
  background-repeat: repeat;
  height: auto;
}
.logo {
  width: 250px;
}
.over {
  overflow: hidden;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 10px 40px !important;
  font-family: "Merienda";
  font-size: 17px;
}
.mob-icon {
  width: 50px;
}
.logoBackground {
  background-image: url("https://greepfireworks.com/images/newconfetti.png");
}
.navbar-nav .nav-link.active {
  color: #fff !important;
  border-radius: 20px 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.fadeInLeft {
  animation: fadeInLeft 1s;
}
.fullpad {
  padding: 0 !important;
  margin: 0 !important;
}
.bg-overlay {
  position: relative;
}
.bg-overlay::before {
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.z {
  position: relative;
  z-index: 2;
}
/* default end */
/* app bar start */
.top-bg {
  background-image: url("./assets/images/topbg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: center;
}
.top-bg::before {
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.top-info li p {
  display: inline-block;
  margin-bottom: 8px;
}
.top-info li svg {
  font-size: 20px;
  margin-right: 16px;
}
#home {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-position: 50% 0px;
}
.home-center {
  height: 100vh;
  vertical-align: middle;
  display: table-cell;
  position: relative;
}
.home-center img {
  width: 200px;
  margin: 0 auto;
}
.down svg {
  color: #ffffff;
  font-size: 55px;
}
.quote {
  font-size: 55px;
  color: #fff;
  font-family: "Merienda";
}
.mark-bg {
  background-color: #d82222;
  color: #1f1d1d;
  font-family: "Merienda";
}
.gpay {
  width: 185px;
}
.estimate {
  background: #6482ad !important;
  padding: 10px !important;
  font-family: "Merienda" !important;
  color: #fff !important;
}
/* app bar end */
/* footer start */
.list-unstyled li {
  padding: 12px 0;
}
.list-unstyled li a {
  text-decoration: none !important;
  color: #ffffff !important;
  font-family: "Merienda";
}
.rules {
  line-height: 35px;
  font-family: "Merienda";
}
.footer-bg {
  background-color: #001b5a;
  font-family: "Merienda";
  color: #ffffff;
}
.ft-logo {
  width: 150px;
}
.ft-icon {
  background: #ff6a00;
  padding: 12px;
  border-radius: 50%;
  color: #141313;
  font-size: 32px;
  line-height: 10px;
}
.ft-details {
  margin: 0 20px;
}
.text_one {
  position: relative;
  padding: 5px 0 0 30px;
  margin-top: -42px;
}
/* footer end */
/* site off start */
.site-off {
  background-image: url(./assets/images/siteoffbanner.jpg);
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.site-off::before {
  background: #000000ab none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.site-of-text h2 {
  position: relative;
  z-index: 2;
}
.site-of-text {
  position: relative;
  z-index: 2;
}
.site-of {
  width: 25%;
}
/* .headnav{
  background-color: rgb(210 46 55) !important
} */
.subheadcolor {
  font-family: "Merienda";
  color: #ff6a00;
}
.h1color {
  color: darkblue;
  font-family: "Merienda";
}
.containerWidth {
  width: 100%;
}

.WholeRetail {
  background: rgba(234, 236, 238, 0.936);
}

.image-container {
  overflow: hidden; /* This ensures the image doesn't overflow its container */
}

.product-image {
  transition: transform 0.3s ease-in-out; /* Smooth zoom transition */
  width: 100%; /* Ensure the image is responsive */
  height: auto; /* Maintain aspect ratio */
}

.image-container:hover .product-image {
  transform: scale(1.2); /* Adjust the scale for the zoom level */
}
.line {
  border-top: 2px solid #272626;
}

.whywe {
  background-image: url("https://gurubhagavancrackers.com/images/whyus.png");
}
.pad {
  padding: 90px 0;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.quality {
  background-color: #fff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 30px 25px;
  position: relative;
  transition: 0.5s;
  max-width: 380px;
  margin-bottom: 75px;
}
.flipInX {
  animation-name: flipInX;
}
.marleft {
  margin-left: 50px;
}
.align-self-center {
  align-self: center !important;
}

::before {
  box-sizing: border-box;
}

div {
  display: block;
  unicode-bidi: isolate;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
::after,
::before {
  box-sizing: border-box;
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.why {
  background-color: #fff;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  padding: 25px 30px 25px;
  position: relative;
  transition: 0.5s;
  max-width: 380px;
  margin-bottom: 75px;
}

.why:hover {
  background: rgb(23, 234, 83);
  transform: translateY(-20px);
  border-radius: 30px;

  transition: 0.5s;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.smlbrdr {
  width: 70px;
  height: 2px;
  background-color: #e11d24;
}

.HOME {
  background: #001b5a;
}
